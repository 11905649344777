<template>
  <div>
    <slot :onClickActive="scrollTo" />
  </div>
</template>
<script setup>
const route = useRoute();
const ids = ref([]);
const selectedId = ref(null);

const props = defineProps({
  scrollMargin: {
    required: false,
    default: 145,
    type: [Number, String],
  },
  scrollTopMargin: {
    required: false,
    default: 140,
    type: [Number, String],
  },
});

onBeforeMount(() => {
  if (process.browser) {
    window.addEventListener("scroll", handleScroll, false);
  }
});

onBeforeUnmount(() => {
  if (process.browser) {
    window.removeEventListener("scroll", handleScroll);
  }
});

function handleScroll(e) {
  document.querySelectorAll(".scrollactive-item").forEach((el) => {
    const id = el.getAttribute("data-id");

    const topData = document.getElementById(id).getBoundingClientRect();
    if (topData) {
      const height = topData.height;
      const top = topData.top;
      if (top <= props.scrollMargin && top > -height + props.scrollMargin) {
        el.classList.add("is-active");
        selectedId.value = id;
      } else {
        if (selectedId.value != id) {
          el.classList.remove("is-active");
        }
      }
    }
  });
}

function scrollTo(e, id = null) {
  if (id) {
    ids.value = [];

    const containerEl = document.getElementById(id);
    const el = e.target;

    el.classList.add("is-active");

    document.querySelectorAll(".scrollactive-item").forEach((el) => {
      if (el.getAttribute("data-id")) {
        const val = el.getAttribute("data-id");
        if (val != id) {
          el.classList.remove("is-active");
        }
      }
    });

    history.replaceState("", document.title, route.path);

    containerEl.style.scrollMarginTop = props.scrollTopMargin + "px";
    containerEl.scrollIntoView({
      behavior: "smooth",
    });
  }
}
</script>
<style>
.scrollactive-item {
  @apply cursor-pointer;
}
</style>