<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center z-[99999999999999] custom-lightbox"
    @keydown.esc="close"
    v-bind="$attrs"
    ref="lightbox"
    id="fullscreen-preview"
    tabindex="0"
  >
    <div
      class="overlay inset-0 bg-black bg-opacity-80 w-[100dvw] h-[100dvh] fixed"
      @click="close"
    ></div>
    <div
      class="relative flex items-center justify-center w-full h-full overflow-hidden rounded-lg"
    >
      <button
        class="absolute z-30 text-2xl font-bold text-white top-7.5 right-7.5 bg-black bg-opacity-10 rounded"
        @click="close"
      >
        <CloseIcon class="w-6 h-6 text-white" />
      </button>

      <button
        v-if="currentIndex > 0"
        class="absolute z-30 text-2xl text-white transform -translate-y-1/2 left-4 top-1/2"
        @click.stop="prev"
        @keydown.left="prev"
      >
        <AngleLeftSwitchIcon class="w-[50px] h-[50px] text-white" />
      </button>
      <button
        v-if="currentIndex < items.length - 1"
        class="absolute z-30 text-2xl text-white transform -translate-y-1/2 right-4 top-1/2"
        @click.stop="next"
        @keydown.right="next"
      >
        <AngleRightSwitchIcon class="w-[50px] h-[50px] text-white" />
      </button>

      <ImageZoom :close="close" :item="item" v-if="type === 'image'" />

      <div
        v-else-if="type === 'video'"
        class="relative rounded-[5px] overflow-hidden flex items-center justify-center"
      >
        <Spinner
          v-if="!isVideoLoad"
          class="absolute mr-2 text-white animate-spin w-14 h-14"
        />
        <img :src="item.poster" class="video-poster" v-show="!isVideoLoad" />
        <video
          v-show="isVideoLoad"
          :src="item.src"
          class="transition-transform duration-300"
          controls
        ></video>

        <div id="video-controls" class="video-controls" data-state="hidden">
          <button
            id="playpause"
            type="button"
            class="items-center justify-center"
            data-state="play"
          >
            <PlayVideoIcon class="w-3 h-3 text-white md:w-4 md:h-4" />
          </button>
          <button
            id="stop"
            type="button"
            class="w-4 h-4 md:w-5 md:h-5"
            data-state="stop"
          >
            <PauseIcon class="w-4 h-4 text-white md:w-5 md:h-5" />
          </button>
          <button id="mute" type="button" data-state="mute">
            <MuteIcon class="w-4 h-4 text-white md:w-5 md:h-5" />
          </button>
          <button id="volinc" type="button" data-state="volup">
            <VolumeUpIcon class="w-4 h-4 text-white md:w-5 md:h-5" />
          </button>
          <div class="relative progress">
            <progress id="progress" value="0" min="0">
              <span id="progress-bar"></span>
            </progress>
            <span id="progress-dot"></span>
          </div>
          <div id="remaining-time" class="text-white"></div>
          <button id="fs" type="button" data-state="go-fullscreen">
            <FullScreenIcon class="w-4 h-4 text-white md:w-5 md:h-5" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const SearchIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/search.svg?component")
);

const PlayMiddleIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/play-middle.svg?component")
);
const PlayIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/play.svg?component")
);
const PlayVideoIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/play-video.svg?component")
);
const FullScreenIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/fullscreen-line.svg?component")
);
const SettingIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/setting.svg?component")
);
const VolumeUpIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/volume-up.svg?component")
);
const PauseIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/pause-mini-line.svg?component")
);
const MuteIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/mute.svg?component")
);
const AngleLeftSwitchIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/angle-left-switch.svg?component")
);
const AngleRightSwitchIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/angle-right-switch.svg?component")
);
const CloseIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/close.svg?component")
);

const props = defineProps({
  items: Array,
  initialIndex: Number,
});

const emit = defineEmits(["update:visible"]);

const zoomer = ref();
const isVideoLoad = ref(false);
const controller = ref(null);
const lightbox = ref(null);
const currentIndex = ref(props.initialIndex);
const state = reactive({});

const type = computed(() => {
  if (props.items[currentIndex.value]) {
    if (props.items[currentIndex.value].type.includes("video")) {
      return "video";
    }
  }
  return "image";
});
const item = computed(() => {
  return props.items[currentIndex.value];
});

const isVisible = computed(() => {
  return currentIndex.value !== null;
});

watch(
  props,
  (newValue, oldValue) => {
    if (newValue.initialIndex != currentIndex.value) {
      currentIndex.value = newValue.initialIndex;
    }
  },
  { deep: true }
);

watch(
  item,
  (newValue, oldValue) => {
    if (type.value == "video") {
      updateCustomVideo();
    }
  },
  { deep: true, flush: "post" }
);
watch(
  lightbox,
  (newValue, oldValue) => {
    if (lightbox.value) {
      lightbox.value.focus();
      document.onkeydown = checkKey;
    }
  },
  { deep: true, flush: "post" }
);

onMounted(() => {
  if (lightbox.value) {
    lightbox.value.focus();
  }
  if (import.meta.browser) {
    document.onkeydown = checkKey;
  }
});

onBeforeUnmount(() => {
  emit("update:visible", null);
  setTimeout(() => {
    console.log("unmounted");
    const containerEl = document.getElementById("gallery");
    if (containerEl) {
      containerEl.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, 5);
  document.onkeydown = null;
});

function checkKey(e) {
  e = e || window.event;
  if (e.keyCode == "37") {
    prev();
  } else if (e.keyCode == "39") {
    next();
  }
}

function closeOnKey(event) {
  if (event.key === "Escape") {
    close();
  }
}

function close() {
  let hasVideo = false;

  if (type.value == "video") {
    hasVideo = true;
  }

  if (!hasVideo) {
    currentIndex.value = null;
    emit("update:visible", null);
    document.onkeydown = null;
    return false;
  }

  var videoControls = document.getElementById("video-controls");
  var video = document.querySelector(".custom-lightbox video");
  var progress = document.getElementById("progress");
  var remainingTime = document.getElementById("remaining-time");
  var progressDot = document.getElementById("progress-dot");
  var progressBar = document.getElementById("progress-bar");

  progressDot.onmousedown = null;
  progressDot.ontouchstart = null;
  videoControls.style.display = "none";

  if (controller.value) {
    controller.value.abort();
    controller.value = null;
  }

  progressBar.style.width = 0;
  progressDot.style.left = 0;
  remainingTime.innerHTML = "00:00";
  video.currentTime = 0;
  progress.value = 0;
  currentIndex.value = null;
  document.onkeydown = null;
  emit("update:visible", null);
}

function updateCustomVideo() {
  // Does the browser actually support the video element?
  var supportsVideo = !!document.createElement("video").canPlayType;

  if (supportsVideo) {
    var videoContainer = document.getElementsByClassName("custom-lightbox")[0];
    var video = document.querySelector(".custom-lightbox video");

    var videoControls = document.getElementById("video-controls");

    // Hide the default controls
    video.controls = false;

    // Display the user defined video controls
    videoControls.style.display = "flex";

    // Obtain handles to buttons and other elements
    var fullscreenPreview = document.getElementById("fullscreen-preview");
    var playpause = document.getElementById("playpause");
    var stop = document.getElementById("stop");
    var mute = document.getElementById("mute");
    var volinc = document.getElementById("volinc");
    // var voldec = document.getElementById('voldec');
    var progress = document.getElementById("progress");
    var progressBar = document.getElementById("progress-bar");
    var progressDot = document.getElementById("progress-dot");
    var remainingTime = document.getElementById("remaining-time");
    var fullscreen = document.getElementById("fs");

    stop.style.display = "none";
    volinc.style.display = "block";
    playpause.style.display = "flex";
    mute.style.display = "none";
    video.muted = false;

    // Set the video container's fullscreen state
    var setRemainingTime = function () {
      // Get the video duration in seconds
      const duration = video.duration;

      // Calculate the remaining time in seconds
      const remaining = duration - video.currentTime;

      if (!remaining) {
        playpause.style.display = "flex";
        stop.style.display = "none";
      }

      // Convert the remaining time to minutes and seconds
      let minutes = Math.floor(remaining / 60);
      const seconds = Math.floor(remaining % 60);

      // Convert the remaining time to hours and minutes
      // const hours = Math.floor(minutes / 60);
      minutes = minutes % 60;

      remainingTime.innerHTML = `-${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    };

    controller.value = new AbortController();

    // Check if the browser supports the Fullscreen API
    var fullScreenEnabled = !!(
      document.fullscreenEnabled ||
      document.mozFullScreenEnabled ||
      document.msFullscreenEnabled ||
      document.webkitSupportsFullscreen ||
      document.webkitFullscreenEnabled ||
      document.createElement("video").webkitRequestFullScreen
    );
    // If the browser doesn't support the Fulscreen API then hide the fullscreen button
    if (!fullScreenEnabled) {
      fullscreen.style.display = "none";
    }

    // Set the video container's fullscreen state
    var setFullscreenData = function (state) {
      fullscreenPreview.setAttribute("data-fullscreen", !!state);
      if (state) {
        // stop.style.display = 'block';
        // volinc.style.display = 'block';
        // videoControls.style.display = 'flex';
      } else {
        // stop.style.display = 'block';
        // volinc.style.display = 'block';
      }
    };

    // Checks if the document is currently in fullscreen mode
    var isFullScreen = function () {
      return !!(
        document.fullScreen ||
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement ||
        document.fullscreenElement
      );
    };

    // Fullscreen
    var handleFullscreen = function () {
      // If fullscreen mode is active...
      if (isFullScreen()) {
        // ...exit fullscreen mode
        // (Note: this can only be called on document)
        if (document.exitFullscreen) document.exitFullscreen();
        else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
        else if (document.webkitCancelFullScreen)
          document.webkitCancelFullScreen();
        else if (document.msExitFullscreen) document.msExitFullscreen();
        setFullscreenData(false);
      } else {
        // ...otherwise enter fullscreen mode
        // (Note: can be called on document, but here the specific element is used as it will also ensure that the element's children, e.g. the custom controls, go fullscreen also)
        if (fullscreenPreview.requestFullscreen) {
          fullscreenPreview.requestFullscreen();
        } else if (fullscreenPreview.mozRequestFullScreen) {
          fullscreenPreview.mozRequestFullScreen();
        } else if (fullscreenPreview.webkitRequestFullScreen) {
          // Safari 5.1 only allows proper fullscreen on the video element. This also works fine on other WebKit browsers as the following CSS (set in styles.css) hides the default controls that appear again, and
          // ensures that our custom controls are visible:
          // figure[data-fullscreen=true] video::-webkit-media-controls { display:none !important; }
          // figure[data-fullscreen=true] .controls { z-index:2147483647; }
          videoContainer.webkitRequestFullScreen();
        } else if (fullscreenPreview.msRequestFullscreen) {
          fullscreenPreview.msRequestFullscreen();
        }
        setFullscreenData(true);
      }
    };

    // Only add the events if addEventListener is supported (IE8 and less don't support it, but that will use Flash anyway)
    if (document.addEventListener) {
      // Wait for the video's meta data to be loaded, then set the progress bar's max value to the duration of the video
      video.addEventListener(
        "loadedmetadata",
        function () {
          progress.setAttribute("max", video.duration);
          checkVideoLoad();
          setRemainingTime();
        },
        { signal: controller.value.signal }
      );

      function checkVideoLoad() {
        if (video.readyState >= 4) {
          isVideoLoad.value = true;
        } else {
          isVideoLoad.value = false;
          setTimeout(checkVideoLoad, 100); // Check again in 100ms
        }
      }

      // Add events for all buttons
      playpause.addEventListener(
        "click",
        function (e) {
          if (video.paused || video.ended) {
            video.play();
            playpause.style.display = "none";
            stop.style.display = "block";
          }
        },
        { signal: controller.value.signal }
      );

      stop.addEventListener(
        "click",
        function (e) {
          video.pause();
          playpause.style.display = "flex";
          stop.style.display = "none";
          // video.currentTime = 0;
          // progress.value = 0;
        },
        { signal: controller.value.signal }
      );
      mute.addEventListener(
        "click",
        function (e) {
          video.muted = false;
          mute.style.display = "none";
          volinc.style.display = "block";
        },
        { signal: controller.value.signal }
      );
      volinc.addEventListener(
        "click",
        function (e) {
          // alterVolume('+');
          video.muted = true;
          volinc.style.display = "none";
          mute.style.display = "block";
        },
        { signal: controller.value.signal }
      );
      // voldec.addEventListener('click', function(e) {
      //   alterVolume('-');
      // });
      fullscreen.addEventListener(
        "click",
        function (e) {
          handleFullscreen();
        },
        { signal: controller.value.signal }
      );

      // As the video is playing, update the progress bar
      video.addEventListener(
        "timeupdate",
        function () {
          // For mobile browsers, ensure that the progress element's max attribute is set
          if (!progress.getAttribute("max"))
            progress.setAttribute("max", video.duration);
          progress.value = video.currentTime;
          progressBar.style.width =
            (video.currentTime / video.duration) * 100 + "%";
          if ((video.currentTime / video.duration) * 100 >= 99) {
            progressDot.style.left = "auto";
            progressDot.style.right = 0;
          } else {
            progressDot.style.right = "auto";
            progressDot.style.left =
              (video.currentTime / video.duration) * 100 + "%";
          }

          setRemainingTime();
        },
        { signal: controller.value.signal }
      );

      // React to the user clicking within the progress bar

      progress.addEventListener(
        "click",
        function (e) {
          const pos =
            (e.pageX -
              progress.offsetLeft -
              progress.offsetParent.offsetLeft -
              30) /
            progress.offsetWidth;

          video.currentTime = pos * video.duration;
        },
        { signal: controller.value.signal }
      );

      progressDotHandler(video, progressDot, progress);

      // Listen for fullscreen change events (from other controls, e.g. right clicking on the video itself)
      document.addEventListener(
        "fullscreenchange",
        function (e) {
          setFullscreenData(
            !!(document.fullScreen || document.fullscreenElement)
          );
        },
        { signal: controller.value.signal }
      );
      document.addEventListener(
        "webkitfullscreenchange",
        function (e) {
          setFullscreenData(!!document.webkitIsFullScreen);
        },
        { signal: controller.value.signal }
      );
      document.addEventListener(
        "mozfullscreenchange",
        function (e) {
          setFullscreenData(!!document.mozFullScreen);
        },
        { signal: controller.value.signal }
      );
      document.addEventListener(
        "msfullscreenchange",
        function (e) {
          setFullscreenData(!!document.msFullscreenElement);
        },
        { signal: controller.value.signal }
      );
    }
  }
}

function progressDotHandler(video, progressDot, progress) {
  progressDot.onmousedown = function (event) {
    function moveAt(pageX) {
      const progressDotHalflWidth = progressDot.offsetWidth / 2;
      const progressOffset = progress.offsetWidth - progressDotHalflWidth;
      const pageOffeset = pageX - progressDot.offsetWidth - 100;
      if (
        progressOffset >= pageOffeset &&
        pageOffeset >= -progressDotHalflWidth
      ) {
        progressDot.style.left = pageOffeset + "px";
      }
    }

    moveAt(event.pageX);

    function onMouseMove(event) {
      moveAt(event.pageX);
    }

    function onMouseUp(e) {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);

      const pos =
        (e.pageX -
          progress.offsetLeft -
          progress.offsetParent.offsetLeft -
          23) /
        progress.offsetWidth;

      video.currentTime = pos * video.duration;
      progressDot.onmouseup = null;
    }

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };
  progressDot.ontouchstart = function (event) {
    function moveAt(pageX) {
      const progressDotHalflWidth = progressDot.offsetWidth / 2;
      const progressOffset = progress.offsetWidth - progressDotHalflWidth;
      const pageOffeset = pageX - progressDot.offsetWidth - 100;
      if (
        progressOffset >= pageOffeset &&
        pageOffeset >= -progressDotHalflWidth
      ) {
        progressDot.style.left = pageOffeset + "px";
      }
    }

    moveAt(event.pageX);

    function onTouchMove(event) {
      moveAt(event.changedTouches[0].pageX);
    }

    function onTouchend(e) {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchend);
      progressDot.ontouchstart = null;
      let eventData = e.changedTouches[0];

      const pos =
        (eventData.pageX -
          progress.offsetLeft -
          progress.offsetParent.offsetLeft -
          15) /
        progress.offsetWidth;

      video.currentTime = pos * video.duration;
      progressDot.ontouchend = null;
    }

    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onTouchend);
  };
  progressDot.ondragstart = function () {
    return false;
  };
}

function prev() {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  }
}

function next() {
  if (currentIndex.value < props.items.length - 1) {
    currentIndex.value++;
  }
}
</script>

<style scoped>
/* Add scoped styles if needed */
.custom-lightbox video {
  @apply !h-[95dvh] !w-[97dvw] object-cover rounded-[5px];
}
.custom-lightbox .video-poster {
  @apply !h-[95dvh] !w-[97dvw] object-cover rounded-[5px];
}
.video-controls {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0px 0px 2px 2px;
  backdrop-filter: blur(12.5px);
  @apply gap-2  lg:gap-6 px-2 md:px-5 h-10 w-full justify-between items-center absolute bottom-0 rounded-b-[5px] z-[999999999999];
}
.video-controls[data-state="hidden"] {
  display: none;
}

.video-controls[data-state="visible"] {
  display: block;
}
.video-controls > * {
  @apply block h-5;
}

.video-controls > *:first-child {
  margin-left: 0;
}
.video-controls .progress {
  @apply cursor-pointer w-[75%] md:w-full rounded-full h-2;
}
.video-controls button {
  border: none;
  cursor: pointer;
  background: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  @apply md:!min-w-[20px] md:h-5 h-4 w-4;
}
.video-controls button:hover,
.video-controls button:focus {
  opacity: 0.5;
}
#remaining-time {
  min-width: 47px;
}
.video-controls progress {
  /* -moz-border-radius: 100%; */
  /* -webkit-border-radius: 100%; */
  @apply rounded-full overflow-hidden text-[#0095dd] border-none w-full h-full block relative;
}
.video-controls progress[data-state="fake"] {
  background: #e6e6e6;
  height: 65%;
}
.video-controls progress span {
  width: 0%;
  height: 100%;
  display: inline-block;
  background-color: #2a84cd;
}
.video-controls #progress-dot {
  width: 20px;
  height: 20px;
  left: 0;
  position: absolute;
  top: -6px;
  z-index: 9999999999999;
  background: white;
  border-radius: 100%;
}
.video-controls progress::-moz-progress-bar {
  background-color: #0095dd;
}

.video-controls progress::-webkit-progress-value {
  background-color: #0095dd;
}
figure[data-fullscreen="true"] video::-webkit-media-controls {
  display: none !important;
}
figure[data-fullscreen="true"] .controls {
  z-index: 2147483647;
}
</style>
