<template>
  <div class="relative w-full h-full">
    <div class="w-full h-full overflow-hidden drag-zoom" ref="areaNode">
      <div
        ref="xxDragZoom"
        class="flex items-center justify-center w-full h-full xx-drag-zoom img-dragg"
        :style="dragZoomNodeStyle"
      >
        <client-only>
          <Transition name="fade" appear>
            <img
              :src="item.src"
              :draggable="false"
              class="duration-300"
              v-click-away="close"
              @click.stop
            />
          </Transition>
        </client-only>
      </div>
    </div>

    <button
      class="absolute flex items-center justify-center w-8 h-8 bg-black rounded-sm shadow-md bg-opacity-30 bottom-7 right-14"
      @click.stop="zoomIn"
      @touchstart="zoomIn"
    >
      <SearchIcon class="w-6 h-6 text-white" />
    </button>
    <button
      class="absolute flex items-center justify-center w-8 h-8 bg-black rounded-sm shadow-md bottom-7 right-4 bg-opacity-30"
      @click.stop="zoomOut"
      @touchstart="zoomOut"
    >
      <MinusIcon class="w-6 h-6 text-white" />
    </button>
  </div>
</template>
<script setup>
const SearchIcon = defineAsyncComponent(() =>
  import("~/assets/svg/heroicons/search.svg?component")
);
const MinusIcon = defineAsyncComponent(() =>
  import("@/assets/svg/heroicons/search-minus.svg?component")
);
const props = defineProps({
  item: Object,
  left: {
    type: Number,
    default: 0,
  },
  top: {
    type: Number,
    default: 0,
  },
  width: Number,
  height: Number,
  allowZoom: {
    type: Boolean,
    default: true,
  },
  maxZoom: {
    type: Number,
    default: 2,
  },
  minZoom: {
    type: Number,
    default: 0.5,
  },
  range: {
    type: Number,
    default: 0.1,
  },
  areaLeft: {
    type: Number,
    default: 0,
  },
  areaTop: {
    type: Number,
    default: 0,
  },
  areaWidth: {
    type: Number,
    default: 200,
  },
  areaHeight: {
    type: Number,
    default: 100,
  },
  close: {
    type: Function,
    default: null,
  },
});

const transform = ref({ x: 100, y: 100, scale: 1 });

const el = ref();
const xxDragZoom = ref(null);
const areaNode = ref(null);
const emit = defineEmits();

const zoom = ref(1);
const grabActive = ref(false);
const currentZoom = ref(1);
const initLeft = ref(props.left);
const initTop = ref(props.top);

const dragZoomNode = computed(() => {
  return xxDragZoom.value;
});

const areaNodeData = computed(() => {
  let obj = {};
  const node = areaNode.value;

  if (node) {
    obj = {
      left: node.clientLeft,
      top: node.clientTop,
      width: node.offsetWidth,
      height: node.offsetHeight,
    };
  } else {
    obj = {
      left: props.areaLeft,
      top: props.areaTop,
      width: props.areaWidth,
      height: props.areaHeight,
    };
  }
  return obj;
});
const dragZoomNodeStyle = computed(() => {
  return {
    transform: `scale(${currentZoom.value})`,
    left: `${initLeft.value}px`,
    top: `${initTop.value}px`,
    width: props.width + "px",
    height: props.height + "px",
  };
});

watch(
  props,
  (newValue, oldValue) => {
    initStyle();
  },
  { deep: true }
);

onMounted(() => {
  dragZoomNode.value.addEventListener("touchstart", touchstart);
  dragZoomNode.value.addEventListener("mousedown", mousedown);
  dragZoomNode.value.addEventListener("wheel", mousescroll);

  initStyle();
});

onBeforeUnmount(() => {
  console.log("listner");
  dragZoomNode.value.removeEventListener("touchstart", touchstart);
  dragZoomNode.value.removeEventListener("mousedown", mousedown);
  dragZoomNode.value.removeEventListener("wheel", mousescroll);
  document.onmouseup = null;
  document.ontouchmove = null;
  document.ontouchend = null;
});

function zoomIn(e) {
  e.stopPropagation();
  currentZoom.value = Math.min(currentZoom.value + 0.1, 3);
}

function zoomOut(e) {
  e.stopPropagation();
  currentZoom.value = Math.max(currentZoom.value - 0.1, 0.5);
}

function mousedown(evt) {
  grabActive.value = true;
  const {
    // left: areaL,
    // top: areaT,
    width: areaW,
    height: areaH,
  } = areaNodeData.value;
  const {
    offsetLeft: dragL,
    offsetTop: dragT,
    offsetWidth: dragW,
    offsetHeight: dragH,
  } = dragZoomNode.value;
  const x = evt.clientX - dragL;
  const y = evt.clientY - dragT;

  dragZoomNode.value.children[0].style.cursor = "grabbing";
  document.onmousemove = (evt) => {
    const zoomNew = currentZoom.value;
    let styleL = evt.clientX - x;
    let styleT = evt.clientY - y;

    if (dragW * zoomNew < areaW) {
      const boundaryL = (dragW * zoomNew - dragW) / 2;
      const boundaryR = areaW - (dragW + boundaryL);

      if (styleL < boundaryL) {
        styleL = boundaryL;
      }
      if (styleL > boundaryR) {
        styleL = boundaryR;
      }
    } else {
      const boundaryL = (dragW * zoomNew - dragW) / 2;
      const boundaryR = -(dragW * zoomNew - areaW - boundaryL);

      if (styleL > boundaryL) {
        styleL = boundaryL;
      }
      if (styleL < boundaryR) {
        styleL = boundaryR;
      }
    }

    if (dragH * zoomNew < areaH) {
      const boundaryT = (dragH * zoomNew - dragH) / 2;
      const boundaryB = areaH - (dragH + boundaryT);

      if (styleT < boundaryT) {
        styleT = boundaryT;
      }
      if (styleT > boundaryB) {
        styleT = boundaryB;
      }
    } else {
      const boundaryT = (dragH * zoomNew - dragH) / 2;
      const boundaryB = -(dragH * zoomNew - areaH - boundaryT);

      if (styleT > boundaryT) {
        styleT = boundaryT;
      }
      if (styleT < boundaryB) {
        styleT = boundaryB;
      }
    }

    dragZoomNode.value.style.left = styleL + "px";
    dragZoomNode.value.style.top = styleT + "px";

    emit("mousemove", evt);
  };
  document.onmouseup = () => {
    document.onmousemove = null;
    document.onmouseup = null;
    if (dragZoomNode.value && dragZoomNode.value.children) {
      dragZoomNode.value.children[0].style.cursor = "grab";
    }

    grabActive.value = false;
  };
}

function touchstart(evt) {
  let evtData = evt.changedTouches[0];
  grabActive.value = true;
  const {
    // left: areaL,
    // top: areaT,
    width: areaW,
    height: areaH,
  } = areaNodeData.value;
  const {
    offsetLeft: dragL,
    offsetTop: dragT,
    offsetWidth: dragW,
    offsetHeight: dragH,
  } = dragZoomNode.value;
  const x = evtData.clientX - dragL;
  const y = evtData.clientY - dragT;

  dragZoomNode.value.children[0].style.cursor = "grabbing";
  document.ontouchmove = (evt) => {
    let evtData = evt.changedTouches[0];
    const zoomNew = currentZoom.value;
    let styleL = evtData.clientX - x;
    let styleT = evtData.clientY - y;

    if (dragW * zoomNew < areaW) {
      const boundaryL = (dragW * zoomNew - dragW) / 2;
      const boundaryR = areaW - (dragW + boundaryL);

      if (styleL < boundaryL) {
        styleL = boundaryL;
      }
      if (styleL > boundaryR) {
        styleL = boundaryR;
      }
    } else {
      const boundaryL = (dragW * zoomNew - dragW) / 2;
      const boundaryR = -(dragW * zoomNew - areaW - boundaryL);

      if (styleL > boundaryL) {
        styleL = boundaryL;
      }
      if (styleL < boundaryR) {
        styleL = boundaryR;
      }
    }

    if (dragH * zoomNew < areaH) {
      const boundaryT = (dragH * zoomNew - dragH) / 2;
      const boundaryB = areaH - (dragH + boundaryT);

      if (styleT < boundaryT) {
        styleT = boundaryT;
      }
      if (styleT > boundaryB) {
        styleT = boundaryB;
      }
    } else {
      const boundaryT = (dragH * zoomNew - dragH) / 2;
      const boundaryB = -(dragH * zoomNew - areaH - boundaryT);

      if (styleT > boundaryT) {
        styleT = boundaryT;
      }
      if (styleT < boundaryB) {
        styleT = boundaryB;
      }
    }

    dragZoomNode.value.style.left = styleL + "px";
    dragZoomNode.value.style.top = styleT + "px";

    emit("mousemove", evtData);
  };
  document.ontouchend = () => {
    document.ontouchmove = null;
    document.ontouchend = null;
    if (dragZoomNode.value && dragZoomNode.value.children) {
      dragZoomNode.value.children[0].style.cursor = "grab";
    }

    grabActive.value = false;
  };
}

function mousescroll(evt) {
  if (evt.preventDefault) {
    evt.preventDefault();
  } else {
    evt.returnValue = false;
  }
  const { deltaY } = evt;

  const {
    left: areaL,
    top: areaT,
    width: areaW,
    height: areaH,
  } = areaNodeData.value;
  const {
    offsetLeft: dragL,
    offsetTop: dragT,
    offsetWidth: dragW,
    offsetHeight: dragH,
  } = dragZoomNode.value;

  let zoomData = currentZoom.value;

  if (!props.allowZoom) {
    return;
  }

  if (deltaY < 0) {
    if (zoomData >= props.maxZoom) {
      return;
    }

    zoomData += props.range;
  } else {
    if (zoomData <= props.minZoom) {
      return;
    }

    zoomData -= props.range;
  }

  currentZoom.value = Number(zoomData.toFixed(1));

  const subtractW = (dragW * currentZoom.value - dragW) / 2;
  const subtractH = (dragH * currentZoom.value - dragH) / 2;
  const currentL = dragL - subtractW;
  const currentT = dragT - subtractW;
  const currentR = dragL + dragW + subtractW;
  const currentB = dragT + dragH + subtractH;

  if (dragW * zoomData < areaW) {
    if (currentL < areaL) {
      dragZoomNode.value.style.left = areaL + subtractW + "px";
    }
    if (currentR > areaW) {
      dragZoomNode.value.style.left = areaW - dragW - subtractW + "px";
    }
  } else {
    if (currentL > areaL) {
      dragZoomNode.value.style.left = areaL + subtractW + "px";
    }
    if (currentR < areaW) {
      dragZoomNode.value.style.left = areaW - dragW - subtractW + "px";
    }
  }
  if (dragH * zoomData < areaH) {
    if (currentT < areaT) {
      dragZoomNode.value.style.top = areaT + subtractH + "px";
    }
    if (currentB > areaH) {
      dragZoomNode.value.style.top = areaH - dragH - subtractH + "px";
    }
  } else {
    if (currentT > areaT) {
      dragZoomNode.value.style.top = areaT + subtractH + "px";
    }
    if (currentB < areaH) {
      dragZoomNode.value.style.top = areaH - dragH - subtractH + "px";
    }
  }
  emit("mousescroll", evt);
}

function initStyle() {
  let tmpLeft = props.left;
  let tmpTop = props.top;
  const { offsetWidth: dragW, offsetHeight: dragH } = dragZoomNode.value;

  tmpLeft = props.left - (dragW * (1 - zoom.value)) / 2;
  tmpTop = props.top - (dragH * (1 - zoom.value)) / 2;

  initLeft.value = tmpLeft;
  initTop.value = tmpTop;
  currentZoom.value = 1;
}
</script>
<style scoped>
.xx-drag-zoom {
  position: absolute;
  user-select: none;
}
.xx-drag-zoom img {
  cursor: grab;
}
.xx-drag-zoom img {
  @apply !h-[95dvh] !w-auto object-contain rounded-[5px];
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
